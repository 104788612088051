.page-home {
  overflow: hidden;

  header {
    height: 100vh;
    width: 100%;
    position: relative;

    .header-background {
      background-size: 45%;
      background-image: url('/images/home-background.svg');
      background-color: var(--color-background);
      background-repeat: no-repeat;
      background-position: 70%;
      filter: grayscale(1);
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;

      @media(max-height: 740px) {
        height: auto;
        min-height: 120vh;
        background-position: 70% 40%;
      }

      @media(max-height: 580px) {
        background-position: 70%;
      }

      @media(max-width: 992px) {
        background-image: none !important;
      }

      @media(max-width: 768px) {
        height: auto;
        min-height: 85vh;
      }
  
      @media(max-height: 835px) and (max-width: 600px) {
        height: auto;
        min-height: 90vh;
        background-position: 70% 40%;
      }
    }

    @media(max-height: 740px) {
      height: auto;
      min-height: 120vh;
      background-position: 70% 40%;
    }

    @media(max-height: 580px) {
      padding-top: 100px;
      background-position: 70%;
    }

    @media(max-width: 992px) {
      background-image: none !important;
    }

    @media(max-width: 768px) {
      height: auto;
      min-height: 85vh;
    }

    @media(max-height: 835px) and (max-width: 600px) {
      height: auto;
      min-height: 90vh;
      background-position: 70% 40%;
    }

    .global-wrapper {
      position: relative;
    }

    .header-inner {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media(max-height: 740px) {
        height: auto;
        min-height: 120vh;
      }

      @media(max-width: 768px) and (min-height: 580px) {
        justify-content: flex-start;
        padding-top: 120px;
        height: auto;
        min-height: 85vh;
      }

      h1 {
        margin-top: 0;
        width: 40%;
        font-size: 55px;
        word-break: break-word;
        line-height: 1.4;

        @media(max-width: 1200px) {
          width: 45%;
        }

        @media(max-width: 992px) {
          width: 80%;
        }

        @media(max-width: 450px) {
          width: 100%;
        }
      }

      p {
        font-size: 18px;
        width: 35%;

        @media(max-width: 992px) {
          width: 80%;
        }

        @media(max-width: 450px) {
          width: 100%;
        }
      }

      .scroll-down {
        font-size: 18px;
        text-decoration: none;
        font-weight: 700;
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 20px;
        height: 70px;
        width: fit-content;

        &:hover {
          cursor: pointer;
          
          span {
            margin-left: 30px;
          }

          &:before {
            left: -20px;
          }

          i {
            opacity: 1;
            margin-left: 40px;
          }
        }

        span {
          z-index: 5;
          position: relative;
          margin-left: 50px;
          transition: margin-left .3s ease-in-out;
        }

        &:before {
          content: '';
          height: 70px;
          width: 70px;
          border-radius: 50%;
          background-color: var(--color-link-background);
          position: absolute;
          left: 0;
          transition: all .3s ease-in-out;
          display: block;
        }

        i {
          font-size: 20px;
          margin-left: 0;
          transition: opacity .2s ease-in-out, margin-left .3s ease-in-out;
          opacity: 0;
        }
      }

      .header-links {
        position: absolute;
        bottom: 100px;

        @media(max-height: 835px) and (max-width: 600px) {
          position: relative;
          bottom: 0;
          margin-top: 80px;
          margin-bottom: 50px;
        }

        @media(max-height: 740px) {
          position: relative;
          bottom: 0;
          margin-top: 80px;
          margin-bottom: 50px;
        }

        @media(max-width: 450px) {
          position: relative;
          bottom: 0;
          margin-top: 80px;
          margin-bottom: 50px;
        }

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
          display: inline-flex;
          align-items: center;

          @media(max-width: 330px) {
            flex-direction: column;
            align-items: flex-start;
          }

          li {
            &:not(:last-of-type) {
              margin-right: 15px;

              @media(max-width: 330px) {
                margin-right: 0;
                margin-bottom: 15px;
              }

              &:after {
                content: '/';
                padding-left: 15px;
              }
            }
          }
        }
      }

      .cta {
        position: absolute;
        right: -230px;
        bottom: 100px;
        transform: rotate(-90deg);
        transform-origin: left;
        display: flex;
        align-items: center;

        @media(max-height: 740px) {
          bottom: 170px;
        }

        @media(max-width: 450px) {
          display: none;
        }

        span {
          font-size: 20px;
          font-weight: 600;
          margin-left: 20px;
        }

        i {
          font-size: 40px;
        }
      }
    }
  }

  .projects {
    padding-top: 50px;

    .section-header {
      padding-bottom: 20px;

      .section-header-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 50px;

        @media(max-width: 380px) {
          flex-direction: column;
          align-items: flex-start;
        }

        h2 {
          font-size: 30px;
          margin: 0;
        }

        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 18px;
          text-decoration: none;
          font-weight: 600;

          @media(max-width: 380px) {
            margin-top: 40px;
          }

          @media(min-width: 400px) {
            &:hover {
              i {
                margin-left: 30px;
              }
            }
          }

          i {
            margin-left: 15px;
            font-size: 25px;
            transition: margin-left .2s ease-in-out;
          }
        }
      }
    }

    .projects-wrapper {
      .project {
        background-color: var(--color-background);

        &:not(:last-child) {
          border-bottom: 1px solid var(--color-border);
        }

        a {
          text-decoration: none;
        }

        @media(min-width: 400px) {
          &:hover .project-inner {
            .link-wrapper {
              span {
                margin-left: 30px;
              }

              &:before {
                left: -20px;
              }

              i {
                opacity: 1;
                margin-left: 40px;
                max-height: unset;
                max-width: unset;
              }
            }
          }
        }

        .project-inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 80px 0;

          @media(max-width: 550px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 50px 0 50px 100px;
          }

          @media(max-width: 350px) {
            padding: 50px 0 50px 0;
          }

          .counter {
            position: absolute;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: var(--color-text-muted);
            font-size: 25px;
            font-weight: 600;

            @media(max-width: 350px) {
              display: none;
            }

            &:before {
              content: '';
              width: 250px;
              background-color: var(--color-text-muted);
              height: 3px;

              @media(max-width: 2280px) {
                width: 200px;
              }

              @media(max-width: 2150px) {
                width: 100px;
              }

              @media(max-width: 2000px) {
                width: 250px;
              }

              @media(max-width: 1800px) {
                width: 150px;
              }

              @media(max-width: 1450px) {
                width: 100px;
              }

              @media(max-width: 1300px) {
                width: 150px;
              }

              @media(max-width: 1100px) {
                width: 70px;
              }
            }
          }

          .project-info {
            display: flex;
            flex-direction: column;
            margin-left: 80px;

            @media(max-width: 550px) {
              margin-left: 0;
              margin-bottom: 40px;
            }

            .project-title {
              font-size: 30px;
              font-weight: 700;
              text-transform: lowercase;

              @media(max-width: 768px) {
                font-size: 25px;
              }
            }

            .project-type {
              font-size: 16px;
              color: var(--color-text-muted);
              text-decoration: underline;
            }
          }

          .link-wrapper {
            font-size: 18px;
            text-decoration: none;
            font-weight: 700;
            position: relative;
            display: flex;
            align-items: center;
            height: 70px;
            width: fit-content;

            @media(max-width: 768px) {
              height: 50px;
            }

            span {
              z-index: 5;
              position: relative;
              margin-left: 50px;
              transition: margin-left .3s ease-in-out;

              @media(max-width: 550px) {
                margin-left: 30px;
              }
            }

            &:before {
              content: '';
              height: 70px;
              width: 70px;
              border-radius: 50%;
              background-color: var(--color-link-background);
              position: absolute;
              left: 0;
              transition: all .3s ease-in-out;
              display: block;

              @media(max-width: 768px) {
                height: 50px;
                width: 50px;
                left: 20px;
              }

              @media(max-width: 550px) {
                left: 0;
              }
            }

            i {
              font-size: 20px;
              margin-left: -10px;
              transition: opacity .2s ease-in-out, margin-left .3s ease-in-out;
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .photography {
    padding-top: 50px;

    .section-header {
      padding-bottom: 20px;

      .section-header-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        margin-top: 50px;

        @media(max-width: 380px) {
          flex-direction: column;
          align-items: flex-start;
        }

        h2 {
          font-size: 30px;
          text-transform: lowercase;
          margin: 0;
        }

        a {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 18px;
          text-decoration: none;
          font-weight: 600;

          @media(max-width: 380px) {
            margin-top: 40px;
          }

          @media(min-width: 400px) {
            &:hover {
              i {
                margin-left: 30px;
              }
            }
          }

          i {
            margin-left: 15px;
            font-size: 25px;
            transition: margin-left .2s ease-in-out;
          }
        }
      }
    }

    .images-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @media(max-width: 1280px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media(max-width: 500px) {
        grid-template-columns: 1fr;
      }

      .image {
        height: 500px;

        @media(min-width: 2400px) {
          height: 900px;
        }

        @media(min-width: 2100px) {
          height: 800px;
        }

        @media(min-width: 1700px) {
          height: 700px;
        }

        @media(max-width: 1280px) {
          &:nth-child(3) {
            display: none;
          }
        }

        @media(max-width: 500px) {
          &:nth-child(2) {
            display: none;
          }
        }

        &:hover img {
          filter: grayscale(0);
          -webkit-filter: grayscale(0);
        }

        img {
          object-fit: cover;
          height: 100%;
          width: 100%;
          filter: grayscale(100%);
          -webkit-filter: grayscale(100%);
          transition: filter .3s ease-in-out;
          transform: translate3d(0, 0, 1px);
        }
      }
    }
  }
}