.cta-wrapper {
  padding-top: 50px;

  .cta-inner {
    display: flex;
    flex-direction: row;
    padding-top: 50px;
    text-decoration: none;
    width: fit-content;

    @media(max-width: 768px) {
      align-items: center;
    }

    @media(max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    @media(min-width: 400px) {
      &:hover .cta-link-wrapper i {
        margin-left: 25px;
      }
    }

    .cta-content {
      display: flex;
      flex-direction: column;
      margin-right: 120px;

      @media(max-width: 992px) {
        margin-right: 50px;
      }

      @media(max-width: 768px) {
        margin-right: 0;
        width: 70%;
      }

      @media(max-width: 500px) {
        width: 100%;
      }

      .cta-title {
        font-size: 45px;
        font-weight: 700;
        text-transform: lowercase;
        margin-bottom: 10px;

        @media(max-width: 768px) {
          font-size: 35px;
        }
      }
    }

    .cta-link-wrapper {
      background-color: var(--color-link-background);
      height: 100px;
      width: 100px;
      border-radius: 50%;
      display: flex;
      align-items: center;

      @media(max-width: 768px) {
        height: 70px;
        width: 70px;
        justify-content: center;
      }

      @media(max-width: 500px) {
        margin-top: 50px;
      }

      i {
        font-size: 50px;
        margin-left: -10px;
        transition: margin-left .3s ease-in-out;

        @media(max-width: 768px) {
          font-size: 30px;
          margin-left: 0;
        }
      }
    }
  }
}