footer {
  height: 500px;
  padding-top: 70px;
  position: relative;
  overflow: hidden;
  margin-top: 100px;

  @media(max-width: 768px) {
    height: auto;
    overflow: visible;
    padding-top: 0;
  }

  &.project-footer {
    background-color: var(--color-background);
    margin-top: 0;
  }

  .footer-wrapper {
    background-color: var(--color-text);
    height: 100%;

    @media(max-width: 768px) {
      padding: 80px 0;
    }
  }

  .footer-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 430px;
    width: 100%;

    @media(max-width: 768px) {
      height: auto;
    }

    .logo-wrapper {
      z-index: 20;

      @media(max-width: 992px) {
        display: none;
      }

      svg {
        height: 80px;
        width: auto;

        @media(max-width: 1300px) {
          height: 40px;
        }

        path {
          fill: #6A6A6A;
        }
      }
    }

    .content-wrapper {
      width: 30%;
      display: flex;
      flex-direction: column;

      @media(max-width: 1600px) {
        width: 45%;
      }

      @media(max-width: 992px) {
        width: 100%;
      }

      .footer-title {
        color: var(--color-white);
        text-align: center;
        font-size: 50px;
        font-weight: 700;
        text-transform: lowercase;
        margin-bottom: 20px;

        @media(max-width: 500px) {
          font-size: 40px;
        }

        @media(max-width: 360px) {
          font-size: 30px;
        }
      }

      .footer-content {
        color: var(--color-white);
        text-align: center;

        @media(max-width: 360px) {
          font-size: 16px;
        }
      }

      a {
        font-size: 18px;
        text-decoration: none;
        font-weight: 700;
        position: relative;
        display: flex;
        align-items: center;
        height: 70px;
        width: fit-content;
        color: var(--color-white);
        margin: 40px auto 0 auto;

        @media(max-width: 500px) {
          height: 50px;
        }

        @media(max-width: 360px) {
          font-size: 16px;
        }

        &:hover {
          span {
            margin-left: 30px;
          }

          &:before {
            left: -20px;
          }

          i {
            opacity: 1;
            margin-left: 40px;
          }
        }

        span {
          z-index: 5;
          position: relative;
          margin-left: 50px;
          transition: margin-left .3s ease-in-out;

          @media(max-width: 500px) {
            margin-left: 25px;
          }
        }

        &:before {
          content: '';
          height: 70px;
          width: 70px;
          border-radius: 50%;
          background-color: #6A6A6A;
          position: absolute;
          left: 0;
          transition: all .3s ease-in-out;
          display: block;

          @media(max-width: 500px) {
            height: 50px;
            width: 50px;
          }
        }

        i {
          font-size: 20px;
          margin-left: -10px;
          transition: opacity .2s ease-in-out, margin-left .3s ease-in-out;
          opacity: 0;
          color: var(--color-white);
        }
      }
    }
  }
}