nav {
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 90;
  background-color: var(--color-background);

  &.mobile {
    background-color: transparent;
    z-index: 300;

    .nav-inner {
      svg {
        path {
          fill: var(--color-white);
          transition: fill .3s ease-in-out .1s;
        }
      }

      .mobile-nav-opener {
        color: var(--color-white);
        transition: color .3s ease-in-out .1s;
      }
    }
  }

  .nav-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100px;

    a {
      width: fit-content;
    }

    svg {
      height: 20px;
      width: auto;

      path {
        transition: fill .3s ease-in-out;
      }
    }

    ul {
      display: inline-flex;
      list-style-type: none;
      padding: 0;
      margin: 0;

      @media(max-width: 768px) {
        display: none;
      }

      li {
        position: relative;
        overflow: hidden;
        padding: 0 10px;

        &:before {
          content: '';
          height: 2px;
          width: 120%;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) translateX(-100%);
          display: block;
          background-color: var(--color-primary);
          left: -5px;
          animation: link-hover-exit .4s cubic-bezier(.71,.13,.34,.87) forwards;
          pointer-events: none;
        }

        &:hover {
          &:before {
            animation: link-hover .4s cubic-bezier(.71,.13,.34,.87) forwards;
          }
        }

        &:not(:last-of-type) {
          margin-right: 20px;
        }

        a {
          text-decoration: none;
          font-weight: 600;
          text-transform: lowercase;
          color: var(--color-text);
        }
      }
    }

    .mobile-nav-opener {
      outline: none;
      padding: 0;
      margin: 0;
      background: none;
      -webkit-appearance: none;
      border: 0;
      font-weight: 600;
      font-size: 16px;
      transition: color .3s ease-in-out .1s;
      will-change: color;
      color: var(--color-text);

      @media(min-width: 768px) {
        display: none;
      }
    }
  }
}

.mobile-nav {
  height: 100%;
  width: 100%;
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(100%);
  z-index: 200;
  will-change: transform;
  transition: all .3s ease-in-out;

  &.show {
    transform: translateX(0);
  }

  .mobile-nav-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        text-align: center;

        &:not(:last-of-type) {
          margin-bottom: 30px;
        }

        a {
          color: var(--color-white);
          text-decoration: none;
          text-transform: lowercase;
          font-size: 20px;

          button {
            background: none;
            border: 0;
            padding: 0;
            margin: 0;
            -webkit-appearance: none;
            outline: none;
            color: inherit;
            font-family: inherit;
            font-size: inherit;
          }
        }
      }
    }
  }
}

@keyframes link-hover {
  0% {
    transform: translateY(-50%) translateX(-100%);
  }
  100% {
    transform: translateY(-50%) translateX(0);
  }
}

@keyframes link-hover-exit {
  0% {
    transform: translateY(-50%) translateX(0);
  }
  100% {
    transform: translateY(-50%) translateX(100%);
  }
}