.page-about {
  header {
    background-color: var(--color-background);
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px 0;

    @media(max-height: 720px) {
      padding: 100px 0;
    }

    @media(max-width: 992px) {
      padding: 50px 0;
    }

    .about-title {
      font-size: 50px;
      text-align: center;
      line-height: 70px;

      @media(max-width: 992px) {
        font-size: 40px;
      }

      @media(max-width: 640px) {
        font-size: 30px;
      }

      @media(max-width: 450px) {
        font-size: 20px;
      }
    }
  }

  .half-fold {
    background-color: var(--color-background);
    padding-bottom: 200px;

    @media(max-width: 768px) {
      padding-bottom: 100px;
    }

    @media(max-width: 400px) {
      padding-bottom: 50px;
    }

    .half-fold-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media(max-width: 768px) {
        flex-direction: column;
      }

      .image-wrapper {
        height: 500px;
        width: 500px;
        position: relative;

        @media(max-width: 1600px) {
          height: 400px;
          width: 400px;
        }

        @media(max-width: 992px) {
          height: 300px;
          width: 300px;
        }

        @media(max-width: 768px) {
          margin-bottom: 100px;
        }

        @media(max-width: 450px) {
          height: 250px;
          width: 250px;
        }

        @media(max-width: 390px) {
          height: 200px;
          width: 200px;
        }

        &:after {
          content: '';
          background-color: var(--color-link-background);
          height: 500px;
          width: 500px;
          border-radius: 50%;
          position: absolute;
          right: -80px;
          bottom: -120px;

          @media(max-width: 992px) {
            height: 300px;
            width: 300px;
            bottom: -80px;
            right: -60px;
          }

          @media(max-width: 768px) {
            height: 200px;
            width: 200px;
            bottom: -60px;
            right: -40px;
          }
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          position: relative;
          z-index: 2;
          transform: translate3d(0, 0, 1px);
        }
      }

      .text-wrapper {
        width: 40%;

        @media(max-width: 768px) {
          width: 100%;
        }

        h2 {
          font-size: 35px;
          font-weight: 700;
          margin-top: 0;
        }

        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .experience-wrapper {
    padding: 100px 0;

    @media(max-width: 400px) {
      padding: 50px 0;
    }

    .experience-inner {
      display: grid;
      grid-template-columns: .2fr 1fr;
      grid-column-gap: 230px;

      @media(max-width: 1300px) {
        grid-column-gap: 130px;
      }

      @media(max-width: 992px) {
        grid-template-columns: 1fr;
      }
    
      h2 {
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 60px;
        margin-top: 0;
      }
  
      .bullet-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 170px;

        @media(max-width: 1300px) {
          grid-column-gap: 100px;
        }

        @media(max-width: 768px) {
          grid-column-gap: 50px;
        }

        @media(max-width: 500px) {
          grid-template-columns: 1fr;
        }
  
        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
          width: 100%;
  
          @media(max-width: 768px) {
            &:not(:last-of-type) {
              margin-bottom: 50px;
            }
          }
  
          @media(max-width: 768px) {
            &:not(:nth-child(2)) {
              margin-bottom: 50px;
            }
          }
  
          li:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  .gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media(max-width: 1280px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 500px) {
      grid-template-columns: 1fr;
    }

    img {
      width: 100%;
      height: 550px;
      object-fit: cover;
      object-position: 50%;
      filter: grayscale(100%);
      -webkit-filter: grayscale(100%);
      transition: filter .3s ease-in-out;
      transform: translate3d(0, 0, 1px);

      @media(min-width: 1700px) {
        height: 700px;
      }

      @media(max-width: 1280px) {
        &:nth-child(3) {
          display: none;
        }
      }

      @media(max-width: 500px) {
        &:nth-child(2) {
          display: none;
        }
      }

      &:hover {
        filter: grayscale(0);
        -webkit-filter: grayscale(0);
      }
    }
  }
}