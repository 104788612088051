.page-project {
  background-color: var(--color-background);
  padding-bottom: 160px;
  
  header {
    height: 90vh;
    background-color: var(--color-background);

    @media(max-height: 700px) {
      height: auto;
      padding-bottom: 50px;
    }

    @media(max-width: 992px) {
      height: 90vh;
    }

    @media(max-width: 768px) {
      height: 70vh;
    }

    img {
      height: 70vh;
      width: 100%;
      object-fit: cover;
      transform: translate3d(0, 0, 1px);

      @media(max-width: 992px) {
        height: 60vh;
      }

      @media(max-width: 300px) {
        height: 50vh;
      }
    }
  }

  .project-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 300px;
    align-items: flex-start;

    @media(max-width: 992px) {
      grid-column-gap: 100px;
    }

    @media(max-width: 768px) {
      grid-template-columns: 1fr;
    }

    .project-info {
      position: sticky;
      top: 100px;
      
      @media(max-width: 768px) {
        position: relative;
        top: 0;
      }

      h1 {
        margin-top: 0;
        margin-bottom: 35px;
        font-size: 60px;

        @media(max-width: 768px) {
          font-size: 40px;
          margin-bottom: 20px;
        }
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          color: var(--color-text-muted);
        }
      }

      a {
        font-size: 18px;
        text-decoration: none;
        font-weight: 700;
        position: relative;
        display: flex;
        align-items: center;
        height: 70px;
        width: fit-content;
        color: var(--color-text);
        margin-top: 50px;

        @media(max-width: 500px) {
          height: 50px;
        }

        @media(max-width: 360px) {
          font-size: 16px;
        }

        &:hover {
          span {
            margin-left: 30px;
          }

          &:before {
            left: -20px;
          }

          i {
            opacity: 1;
            margin-left: 40px;
          }
        }

        span {
          z-index: 5;
          position: relative;
          margin-left: 50px;
          transition: margin-left .3s ease-in-out;

          @media(max-width: 500px) {
            margin-left: 25px;
          }
        }

        &:before {
          content: '';
          height: 70px;
          width: 70px;
          border-radius: 50%;
          background-color: var(--color-link-background);
          position: absolute;
          left: 0;
          transition: all .3s ease-in-out;
          display: block;

          @media(max-width: 500px) {
            height: 50px;
            width: 50px;
          }
        }

        i {
          font-size: 20px;
          margin-left: -10px;
          transition: opacity .2s ease-in-out, margin-left .3s ease-in-out;
          opacity: 0;
          color: var(--color-text);
        }
      }
    }

    .project-content {
      h3 {
        margin-top: 0;
        font-size: inherit;
        margin-bottom: 15px;

        &:not(:first-child) {
          margin-top: 50px;
        }
      }

      p:last-child {
        margin-bottom: 0; 
      }
    }
  }

  .project-content-image {
    margin-top: 150px;
    width: 100%;

    @media(max-width: 768px) {
      margin-top: 50px;
    }

    img {
      width: 100%;
      object-fit: cover;
      height: auto;
      transform: translate3d(0, 0, 1px);
    }
  }

  .project-line {
    height: 1px;
    width: 100%;
    background-color: var(--color-line);
    margin: 150px 0;

    @media(max-width: 768px) {
      margin: 50px 0;
    }
  }

  .project-accomplishments {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 300px;
    align-items: flex-start;

    @media(max-width: 992px) {
      grid-column-gap: 100px;
    }

    @media(max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &__content {
      position: sticky;
      top: 100px;

      @media(max-width: 768px) {
        position: relative;
        top: 0;
        margin-bottom: 50px;
      }

      h2 {
        margin-top: 0;
        font-size: 20px;
        margin-bottom: 15px;
      }

      p {
        margin-bottom: 10px;
      }
    }

    &__image {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        transform: translate3d(0, 0, 1px);
      }
    }
  }

  .project-thanks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 300px;

    @media(max-width: 992px) {
      grid-column-gap: 100px;
    }

    @media(max-width: 768px) {
      grid-template-columns: 1fr;
    }

    &__title {
      h2 {
        font-size: 60px;
        line-height: 74px;
        margin: 0;

        @media(max-width: 992px) {
          font-size: 50px;
          line-height: 60px;
        }

        @media(max-width: 768px) {
          font-size: 40px;
          line-height: 50px;
          margin-bottom: 30px;
        }
      }
    }

    &__content {
      p {
        margin: 0;
      }
    }
  }

  .project-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    margin-top: 150px;

    @media(max-width: 992px) {
      display: flex;
      flex-direction: column;
    }

    @media(max-width: 768px) {
      margin-top: 50px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transform: translate3d(0, 0, 1px);

      @media(max-width: 500px) {
        height: auto;
      }

      &.horizontal {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }
  }
}