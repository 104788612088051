body.___loading {
  .heading-js-wrapper span {
    animation-delay: 2s !important;
  }
}

.heading-js-wrapper {
  overflow: hidden;
  display: block;

  span {
    transform: translateY(130%);
    display: block;
    animation: translate-up .75s var(--animation-bezeren) forwards;
    will-change: transform;
  }
}

.loadup-wrapper {
  overflow: hidden;

  .loadup {
    will-change: opacity;
    opacity: 0;
  }
}