.loader-wrapper {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  pointer-events: none;
  display: none;

  .loader {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 400;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    animation: pointer-events 0s ease-in-out forwards 1.8s;
    display: flex;

    &:before {
      content: '';
      display: block;
      height: 50%;
      width: 100%;
      background-color: var(--color-primary);
      position: absolute;
      top: 0;
      transform: translateY(0);
      left: 0;
      animation: loading-up .5s cubic-bezier(.71,.13,.34,.87) forwards 1.8s;
    }

    &:after {
      content: '';
      display: block;
      height: 50%;
      width: 100%;
      background-color: var(--color-primary);
      position: absolute;
      bottom: 0;
      transform: translateY(0);
      left: 0;
      animation: loading-down .5s cubic-bezier(.71,.13,.34,.87) forwards 1.8s;
    }

    svg {
      height: 50px;
      z-index: 20;
      animation: loading-fadeout .5s ease-in-out forwards 1.2s;
    }

    .bar {
      width: 200px;
      height: 3px;
      margin-top: 50px;
      position: relative;
      z-index: 20;
      animation: loading-fadeout .5s ease-in-out forwards 1.2s;

      &:before {
        content: '';
        display: block;
        width: 0;
        position: absolute;
        background-color: var(--color-text-muted);
        height: 3px;
        animation: loading 1.5s cubic-bezier(.71,.28,.45,.88) forwards;
      }
    }
  }
}

@keyframes loading {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes loading-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes loading-down {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes loading-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes pointer-events {
  0% {
    pointer-events: all;
  }
  100% {
    pointer-events: none;
  }
}