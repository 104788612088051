.page-transition {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999;
    pointer-events: none;

    &.animate {
        pointer-events: all;

        .page-transition__inner {
            opacity: 1;
            animation: transition-in .3s cubic-bezier(.5,.48,.8,.58) forwards;
        }
    }

    &__inner {
        height: 100%;
        width: 100%;
        background-color: var(--color-text);
        position: absolute;
        transform: translateY(100%);
        animation: transition-out .3s cubic-bezier(.5,.48,.8,.58) forwards;
    }
}

@keyframes transition-in {
    0% {
        transform: translateY(100%);
        opacity: 1;
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes transition-out {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}