.page-photography {
  header {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background);
    padding: 100px 0;

    @media(max-width: 992px) {
      padding: 50px 0;
    }

    h1 {
      font-size: 50px;
      text-align: center;
      max-width: 40%;
      font-weight: inherit;
      line-height: 70px;
      margin: 0;

      @media(max-width: 1350px) {
        max-width: 50%;
      }

      @media(max-width: 1050px) {
        max-width: 60%;
      }

      @media(max-width: 992px) {
        font-size: 40px;
      }

      @media(max-width: 768px) {
        max-width: 70%;
      }

      @media(max-width: 670px) {
        max-width: 100%;
      }

      @media(max-width: 470px) {
        font-size: 30px;
      }
    }
  }

  .categories-wrapper {
    background-color: var(--color-background);
    padding-bottom: 100px;

    .categories-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 50px;
      grid-row-gap: 50px;

      @media(max-width: 768px) {
        grid-template-columns: 1fr;
      }

      .category {
        height: 600px;
        width: 100%;
        position: relative;

        @media(min-width: 1600px) {
          height: 80vh;
        }

        @media(max-width: 450px) {
          height: 500px;
        }

        &:hover {
          div {
            transform: scale3d(.9,.9,1);
          }

          figure {
            img {
              transform: scale3d(1.25,1.25,1);
            }
          }
        }

        div,
        figure img,
        figure:after {
          height: 100%;
          overflow: hidden;
          transition: transform .75s cubic-bezier(.165,.84,.44,1),-webkit-transform .75s cubic-bezier(.165,.84,.44,1);
          transform: scale3d(1,1,1);
        }

        figure {
          margin: 0;
          position: relative;
          display: flex;
          justify-content: center;
          height: 100%;

          &:after {
            content: '';
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
          }

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            transform: translate3d(0, 0, 1px);
          }

          figcaption {
            position: absolute;
            bottom: 30px;
            z-index: 40;
            color: var(--color-white);
            font-size: 20px;
            font-weight: 700;
            transform-style: preserve-3d;
          }
        }
      }
    }
  }
}