.page-projects {
  header {
    margin-top: 100px;
    padding: 100px 0;

    @media(max-width: 350px) {
      padding: 50px 0;
    }

    h1 {
      font-size: 35px;
      margin: 0;
      font-weight: 700;
      line-height: 50px;
    }
  }

  .projects-wrapper {
    .project {
      background-color: var(--color-background);

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-border);
      }

      a {
        text-decoration: none;
      }

      @media(min-width: 400px) {
        &:hover .project-inner {
          .image-wrapper {
            width: 800px;
          }

          .link-wrapper {
            span {
              margin-left: 30px;
            }

            &:before {
              left: -20px;
            }

            i {
              opacity: 1;
              margin-left: 40px;
              max-height: unset;
              max-width: unset;
            }
          }
        }
      }

      .project-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 80px 0;

        @media(max-width: 550px) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 50px 0 50px 100px;
        }

        @media(max-width: 350px) {
          padding: 50px 0 50px 0;
        }

        .counter {
          position: absolute;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          color: var(--color-text-muted);
          font-size: 25px;
          font-weight: 600;

          @media(max-width: 350px) {
            display: none;
          }

          &:before {
            content: '';
            width: 250px;
            background-color: var(--color-text-muted);
            height: 3px;

            @media(max-width: 2280px) {
              width: 200px;
            }

            @media(max-width: 2150px) {
              width: 100px;
            }

            @media(max-width: 2000px) {
              width: 250px;
            }

            @media(max-width: 1800px) {
              width: 150px;
            }

            @media(max-width: 1450px) {
              width: 100px;
            }

            @media(max-width: 1300px) {
              width: 150px;
            }

            @media(max-width: 1100px) {
              width: 70px;
            }
          }
        }

        .project-info {
          display: flex;
          flex-direction: column;
          margin-left: 80px;

          @media(max-width: 550px) {
            margin-left: 0;
            margin-bottom: 40px;
          }

          .project-title {
            font-size: 30px;
            font-weight: 700;
            text-transform: lowercase;

            @media(max-width: 768px) {
              font-size: 25px;
            }
          }

          .project-type {
            font-size: 16px;
            color: var(--color-text-muted);
            text-decoration: underline;
          }
        }

        .link-wrapper {
          font-size: 18px;
          text-decoration: none;
          font-weight: 700;
          position: relative;
          display: flex;
          align-items: center;
          height: 70px;
          width: fit-content;

          @media(max-width: 768px) {
            height: 50px;
          }

          span {
            z-index: 5;
            position: relative;
            margin-left: 50px;
            transition: margin-left .3s ease-in-out;

            @media(max-width: 550px) {
              margin-left: 30px;
            }
          }

          &:before {
            content: '';
            height: 70px;
            width: 70px;
            border-radius: 50%;
            background-color: var(--color-link-background);
            position: absolute;
            left: 0;
            transition: all .3s ease-in-out;
            display: block;

            @media(max-width: 768px) {
              height: 50px;
              width: 50px;
              left: 20px;
            }

            @media(max-width: 550px) {
              left: 0;
            }
          }

          i {
            font-size: 20px;
            margin-left: -10px;
            transition: opacity .2s ease-in-out, margin-left .3s ease-in-out;
            opacity: 0;
          }
        }
      }
    }
  }
}