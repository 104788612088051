.page-contact {
  .contact-main {
    margin-top: 100px;
    padding: 100px 0;
    background-color: var(--color-background);

    @media(max-width: 992px) {
      padding: 50px 0;
    }

    .contact-main-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media(max-width: 992px) {
        flex-direction: column-reverse;
      }

      .image-wrapper {
        height: 700px;
        width: 45%;

        @media(max-width: 1600px) {
          height: 600px;
        }

        @media(max-width: 992px) {
          width: 100%;
          margin-top: 100px;
        }

        @media(max-width: 500px) {
          height: 400px;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: translate3d(0, 0, 1px);
        }
      }

      .contact-content {
        width: 45%;

        @media(max-width: 992px) {
          width: 100%;
        }

        h1 {
          font-size: 55px;
          font-weight: 700;
          width: 60%;
          margin-top: 0;
          margin-bottom: 80px;

          @media(max-width: 2000px) {
            width: 70%;
          }

          @media(max-width: 1300px) {
            width: 90%;
          }

          @media(max-width: 768px) {
            width: 80%;
          }
        }

        .contact-options {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 80px;

          @media(max-width: 500px) {
            flex-direction: column;
          }

          .contact-option {
            width: 45%;

            @media(max-width: 500px) {
              width: 100%;

              &:first-child {
                margin-bottom: 40px;
              }
            }

            .option-title {
              font-size: 20px;
              font-weight: 700;
              margin-bottom: 10px;
              display: block;

              &:before {
                content: '';
                height: 10px;
                width: 10px;
                background-color: var(--color-text);
                border-radius: 50%;
                display: inline-flex;
                justify-content: center;
                margin-right: 10px;
              }
            }

            ul {
              list-style-type: none;
              padding: 0;
              margin: 0;

              li {
                margin-bottom: 5px;

                a {
                  text-decoration: none;
                }
              }
            }
          }
        }

        .contact-text-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          width: fit-content;

          .hover-circle {
            height: 92px;
            width: 92px;
            background-color: var(--color-text);
            border-radius: 50%;
            display: inline-flex;
            position: absolute;
            left: 0;
            transition: height .2s ease-in-out, width .2s ease-in-out;

            @media(max-width: 450px) {
              height: 70px;
              width: 70px;
            }

            @media(max-width: 350px) {
              display: none;
            }
          }

          .contact-text {
            font-size: 20px;
            mix-blend-mode: difference;
            color: var(--color-white);
            padding-left: 30px;

            @media(max-width: 450px) {
              font-size: 16px;
            }

            @media(max-width: 350px) {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}