@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@import 'components/nav';
@import 'components/footer';
@import 'components/js';
@import 'components/animations';
@import 'components/footer_cta';
@import 'components/loader';
@import 'components/page-transition';

@import 'pages/home';
@import 'pages/project';
@import 'pages/about';
@import 'pages/projects';
@import 'pages/photography';
@import 'pages/gallery_item';
@import 'pages/contact';

@import 'fontawesome/brands';
@import 'fontawesome/duotone';
@import 'fontawesome/fontawesome';
@import 'fontawesome/regular';
@import 'fontawesome/solid';
@import 'fontawesome/v4-shims';
@import 'fontawesome/light';

/* CSS Custom Properties Definitions */

:root {
  --maxWidth-none: "none";
  --maxWidth-xs: 20rem;
  --maxWidth-sm: 24rem;
  --maxWidth-md: 35rem;
  --maxWidth-lg: 45rem;
  --maxWidth-xl: 60rem;
  --maxWidth-2xl: 80rem;
  --maxWidth-3xl: 90rem;
  --maxWidth-4xl: 120rem;
  --maxWidth-full: "100%";
  --maxWidth-wrapper: var(--maxWidth-2xl);
  --spacing-px: "1px";
  --spacing-0: 0;
  --spacing-1: 0.25rem;
  --spacing-2: 0.5rem;
  --spacing-3: 0.75rem;
  --spacing-4: 1rem;
  --spacing-5: 1.25rem;
  --spacing-6: 1.5rem;
  --spacing-8: 2rem;
  --spacing-10: 2.5rem;
  --spacing-12: 3rem;
  --spacing-16: 4rem;
  --spacing-20: 5rem;
  --spacing-24: 6rem;
  --spacing-32: 8rem;
  --fontFamily-sans: 'Poppins', sans-serif;
  --fontFamily-serif: 'Poppins', sans-serif,
    Times, serif;
  --font-body: var(--fontFamily-serif);
  --font-heading: var(--fontFamily-sans);
  --fontWeight-normal: 400;
  --fontWeight-medium: 500;
  --fontWeight-semibold: 600;
  --fontWeight-bold: 700;
  --fontWeight-extrabold: 800;
  --fontWeight-black: 900;
  --fontSize-root: 16px;
  --lineHeight-none: 1;
  --lineHeight-tight: 1.1;
  --lineHeight-normal: 1.5;
  --lineHeight-relaxed: 1.625;
  /* 1.200 Minor Third Type Scale */
  --fontSize-0: 0.833rem;
  --fontSize-1: 1rem;
  --fontSize-2: 1.2rem;
  --fontSize-3: 1.44rem;
  --fontSize-4: 1.728rem;
  --fontSize-5: 2.074rem;
  --fontSize-6: 2.488rem;
  --fontSize-7: 2.986rem;
  --color-primary: var(--color-text);
  --color-text: #141414;
  --color-text-light: #4f5969;
  --color-heading: #1a202c;
  --color-heading-black: var(--color-text);
  --color-accent: #d1dce5;
  --color-background: #FAFAFA;
  --color-text-muted: #CBCBCB;
  --color-border: #e1e1e1;
  --color-line: #c1c1c1;
  --color-link-background: #E6E6E6;
  --color-white: #FFFFFF;
  --animation-bezeren: cubic-bezier(0.5, 0, 0, 1);
}

/* HTML elements */

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--color-background);
}

::-webkit-scrollbar-thumb {
  background: var(--color-text);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-text);
}

*,
:after,
:before {
  box-sizing: border-box;
}

html {
  line-height: var(--lineHeight-normal);
  font-size: var(--fontSize-root);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--font-body);
  font-size: var(--fontSize-1);
  color: var(--color-text);
  margin: 0;
  padding: 0;

  &.___loading:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: var(--color-text);
    animation: loading-fadeout .3s ease-in-out forwards 1.2s;
  }
}

hr {
  background: var(--color-accent);
  height: 1px;
  border: 0;
}

/* Heading */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  margin-top: var(--spacing-12);
  margin-bottom: var(--spacing-6);
  line-height: var(--lineHeight-tight);
  letter-spacing: -0.025em;
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--fontWeight-bold);
  color: var(--color-heading);
}

h1 {
  font-weight: var(--fontWeight-black);
  font-size: var(--fontSize-6);
  color: var(--color-heading-black);
}

h2 {
  font-size: var(--fontSize-5);
}

h3 {
  font-size: var(--fontSize-4);
}

h4 {
  font-size: var(--fontSize-3);
}

h5 {
  font-size: var(--fontSize-2);
}

h6 {
  font-size: var(--fontSize-1);
}

h1 > a {
  color: inherit;
  text-decoration: none;
}

h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  text-decoration: none;
  color: inherit;
}

/* Prose */

p {
  line-height: var(--lineHeight-relaxed);
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  margin: var(--spacing-0) var(--spacing-0) var(--spacing-8) var(--spacing-0);
  padding: var(--spacing-0);
}

ul,
ol {
  margin-left: var(--spacing-0);
  margin-right: var(--spacing-0);
  padding: var(--spacing-0);
  margin-bottom: var(--spacing-8);
  list-style-position: outside;
  list-style-image: none;
}

li > ul {
  margin-left: var(--spacing-8);
  margin-top: calc(var(--spacing-8) / 2);
}

blockquote {
  color: var(--color-text-light);
  margin-left: calc(-1 * var(--spacing-6));
  margin-right: var(--spacing-8);
  padding: var(--spacing-0) var(--spacing-0) var(--spacing-0) var(--spacing-6);
  border-left: var(--spacing-1) solid var(--color-primary);
  font-size: var(--fontSize-2);
  font-style: italic;
  margin-bottom: var(--spacing-8);
}

blockquote > :last-child {
  margin-bottom: var(--spacing-0);
}

blockquote > ul,
blockquote > ol {
  list-style-position: inside;
}

table {
  width: 100%;
  margin-bottom: var(--spacing-8);
  border-collapse: collapse;
  border-spacing: 0.25rem;
}

table thead tr th {
  border-bottom: 1px solid var(--color-accent);
}

/* Link */

a {
  color: var(--color-primary);
  word-break: break-word;
}

a:hover,
a:focus {
  text-decoration: none;
}

/* Custom classes */

.global-wrapper {
  margin: var(--spacing-0) auto;
  max-width: var(--maxWidth-wrapper);
  padding: 0 var(--spacing-5);

  @media(min-width: 2000px) {
    max-width: var(--maxWidth-4xl);
  }

  @media(max-width: 2000px) {
    max-width: var(--maxWidth-3xl);
  }

  @media(max-width: 1600px) {
    max-width: var(--maxWidth-2xl);
  }

  @media(max-width: 1300px) {
    max-width: var(--maxWidth-xl);
  }

  @media(max-width: 992px) {
    max-width: var(--maxWidth-lg);
  }

  @media(max-width: 768px) {
    max-width: var(--maxWidth-md);
  }
}