.page-gallery-item {
  header {
    margin-top: 100px;
    padding: 150px 0;
    background-color: var(--color-background);

    @media(max-width: 992px) {
      padding: 100px 0;
    }

    @media(max-width: 500px) {
      padding: 50px 0;
    }

    .header-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      h1 {
        font-size: 50px;
        font-weight: 400;
        margin: 0;
        line-height: 70px;

        @media(max-width: 992px) {
          font-size: 40px;
        }

        @media(max-width: 470px) {
          font-size: 30px;
        }
      }
    }
  }

  .categories-wrapper {
    background-color: var(--color-background);
    padding-bottom: 100px;

    .categories-inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 50px;
      grid-row-gap: 50px;

      @media(max-width: 992px) {
        display: flex;
        flex-direction: column;
      }

      img {
        height: 90vh;
        width: 100%;
        object-fit: cover;
        transform: translate3d(0, 0, 1px);

        @media(max-width: 500px) {
          height: auto;
        }

        &.horizontal {
          grid-column-start: 1;
          grid-column-end: 3;
        }
      }
    }
  }
}